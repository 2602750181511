<template>
  <div id="per_center">
    <div class="main">
      <div class="main_top">
        <div class="main_top_wrapper">
          <img src="@/assets/image/HelpCenter/help_title.png">
          <div>
            <el-input
              placeholder="请输入问题关键词"
              clearable
              @keydown.enter.native="handleSearch"
              v-model.trim="keyWord">
              <span slot="prefix">
                <img src="@/assets/image/HelpCenter/search.png">
              </span>
            </el-input>
          </div>
        </div>
      </div>
      <div class="main_content">
        <div class="help_question">
          <div class="question_top">
            <el-card shadow="hover" :body-style="card_style" @click.native="goHelpDetail(buyMenu.cat_id,buyMenu.id)">
              <img src="@/assets/image/HelpCenter/buy.png">
              买家相关
            </el-card>
            <el-card shadow="hover" :body-style="card_style" @click.native="goHelpDetail(sellMenu.cat_id,sellMenu.id)">
              <img src="@/assets/image/HelpCenter/sell.png">
              卖家相关
            </el-card>
            <el-card shadow="hover" :body-style="card_style" @click.native="goHelpDetail(afterMenu.cat_id,afterMenu.id)">
              <img src="@/assets/image/HelpCenter/afterbuy.png">
              售后相关
            </el-card>
            <el-card shadow="hover" :body-style="card_style" @click.native="goHelpDetail(otherMenu.cat_id,otherMenu.id)">
              <img src="@/assets/image/HelpCenter/other.png">
              其他相关
            </el-card>
          </div>
          <div class="question_middle" @click="goHelpDetail(buyMenu.cat_id,buyMenu.id)">
            <div class="title">全部问题</div>
            <span class="text_dec">针对用户常见的使用问题，答案都在这里哦～</span>
            <el-button type="primary" round class="qes_btn" @click="goHelpDetail(buyMenu.cat_id,buyMenu.id)">查看详情<i class="el-icon-arrow-right"></i></el-button>
          </div>
          <div class="question_bottom" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
            <swiper
              :options="swiperOption"
              ref="mySwiper">
              <swiper-slide v-for="item in allQuestion" :key="item.id" >
                <div @click="goHelpDetail(item.cat_id,item.id)">
                  <div class="swiper_top">
                    <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
                      <span class="swiper_title"><img src="@/assets/image/HelpCenter/swiperlt.png">{{ item.title }}</span>
                    </el-tooltip>
                  </div>
                  <router-link class="detail_btn" :to="{name: 'helpDetail',query: {id: item.id,cat_id: item.cat_id}}">查看详情<i class="el-icon-arrow-right"></i></router-link>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="swiper-button-next" slot="button-next">
                <i class="el-icon-arrow-right"></i>
              </div>
            </swiper>
          </div>
          <!-- <div class="question_tip">
            找不到答案？<a>点击联系在线客服<i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i></a>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiHelpCenter, apiHelpAll, apiHelpDetail, apiHelpSearch } from "@/request/API";
import "swiper/dist/css/swiper.css";
export default {
  name: '',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      keyWord: '',
      card_style: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '248px',
        height: '128px',
        boxSizing: 'border-box'
      },
      btnplay: true,
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 20,
        slidesPerGroup: 1,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      buyMenu: {},
      sellMenu: {},
      otherMenu: {},
      afterMenu: {},
      allQuestion: []
    }
  },
  methods: {
    goHelpDetail(cat_id, id) {
      this.$router.push({
        name: 'helpDetail',
        query: {
          cat_id,
          id
        }
      })
    },
    handleSearch() {
      this.$router.push({
        name:'helpCenterSearch',
        query:{
          keyWord: this.keyWord
        }
      })
    },
     on_bot_enter() {
        this.swiper.autoplay.stop();
    },
    on_bot_leave() {
        this.swiper.autoplay.start();
    },
  },
  created() {
    apiHelpCenter().then(res => {
      let array = res.data
      array.map(item => {
        if(item.cat_id == 9){
          this.buyMenu.cat_id = 9
          this.buyMenu.id = item.list[0].id
        } else if(item.cat_id == 10){
          this.sellMenu.cat_id = 10
          this.sellMenu.id = item.list[0].id
        } else if(item.cat_id == 11){
          this.afterMenu.cat_id = 11
          this.afterMenu.id = item.list[0].id
        } else if(item.cat_id == 12){
          this.otherMenu.cat_id = 12
          this.otherMenu.id = item.list[0].id
        }
      });
    })
    apiHelpAll().then(res => {
      this.allQuestion = res.data.list
    })
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  }
}
</script>

<style lang='less' scoped>

#per_center {
  width: 1200px;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  min-height: 644px;
  .nav_menu {
    width: 243px;
    //height: 727px;
    margin-right: 9px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      .nav_menu_title {
        height: 72px;
        background: #FFDB51;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 32px;

        .nav_menu_title_img {
          width: 37px;
          height: 33px;
          margin-right: 6px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .nav_menu_title_text {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-right: 58px;
        }
      }

      .nav_menu_content {
        padding: 11px 0 15px 0;
        //padding-top: 11px;
        min-height: 644px;
        background: #FFFFFF;
        border-radius: 0px 0px 20px 20px;

        .nav_menu-list > .menuList > .el-menu-item {
          height: 40px;
          line-height: 40px;
          padding-left: 59px !important;
          position: relative;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;

          div {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 5px;
            background: #000000;
            position: absolute;
            top: 17px;
            left: 30px;
          }
        }

        .nav_menu-list > .menuList > .is-active, .nav_menu-list > .menuList > .el-menu-item:hover {
          color: #FFFFFF !important;
          background: #283748;

          div {
            background: #FFFFFF;
          }
        }

        /deep/ .el-menu-item, /deep/ .el-submenu__title {
          height: 40px;
          line-height: 40px;
          padding-left: 59px !important;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
        }

        /deep/ .is-active > .el-submenu__title {
          color: #FFFFFF !important;

          div {
            background: #FFFFFF !important;
          }
        }
        /deep/ .el-submenu__title:hover, .el-submenu__title:focus {
          color: #FFFFFF;
          background: #283748;

          div {
            background: #FFFFFF !important;
          }
        }
        /deep/ .el-submenu > .el-submenu__title {
          div {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 5px;
            background: #000000;
            position: absolute;
            top: 17px;
            left: 30px;
          }
        }

        /deep/ .el-menu--inline {
          background: #F4F4F4;
        }

        /deep/ .el-menu--inline > .el-menu-item {
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
        }

        /deep/ .el-menu--inline > .el-menu-item:hover {
          background: #E2E2E2;
          position: relative;

          div {
            position: absolute;
            width: 2px;
            height: 16px;
            background: #8F8F8F;
            border-radius: 1px;
            top: 12px;
            left: 35px;
          }
        }

        /deep/ .el-submenu > .el-menu--inline > .is-active {
          background: #E2E2E2;
          position: relative;

          div {
            position: absolute;
            width: 2px;
            height: 16px;
            background: #8F8F8F;
            border-radius: 1px;
            top: 12px;
            left: 35px;
          }
        }

        /*菜单关闭*/
        /deep/ .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
          -webkit-transform: rotateZ(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotateZ(-90deg);
        }
        /*菜单展开*/
        /deep/ .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
          -webkit-transform: rotateZ(0deg);
          -ms-transform: rotate(0deg);
          transform: rotateZ(0deg);
        }
      }

  }

  .main {
    width: 1200px;
    //height: 727px;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    // position: relative;
    .main_top{
      height: 230px;
      background: url("../../assets/image/HelpCenter/bg_top.png") no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .main_top_wrapper{
        width: 450px;
        text-align: center;
        padding-top: 41px;
        >img{
          width: 257px;
          height: 31px;
          margin: 0 0 22px;
        }
        /deep/input{
          height: 50px;
          border-radius: 23px;
          padding-left: 55px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          border: none;
          // color: #cacdd6;
        }
        /deep/.el-input__prefix{
          width: 18px;
          height: 18px;
          top: calc(50% - 9px);
          left: 26px;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .main_content{
      position: relative;
      top: -67px;
      // top: 174px;
      left: 82px;
      width: 1036px;
    }
  }
}
.help_question{
  .question_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-card{
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #000000;
      cursor: pointer;
      &:hover{
        border: 1px solid #434ED6;
      }
      img{
        width: 50px;
        height: 50px;
      }
    }
  }
  .question_middle{
    height: 156px;
    background: url("../../assets/image/HelpCenter/questionBg.png") no-repeat;
    background-size: contain;
    border-radius: 16px;
    margin: 18px 0;
    font-family: PingFang SC, PingFang SC-Semibold;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 22px 0 0 70px;
    box-sizing: border-box;
    cursor: pointer;
    .title{
      font-weight: 600;
      color: #000000;
      font-size: 20px;
    }
    .text_dec{
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      display: block;
      margin: 10px 0;
    }
    .qes_btn{
      width: 98px;
      height: 28px;
      background: #283748;
      border: none;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      padding: 0 0 0 5px;
    }
  }
  .question_bottom{
    .swiper-container{
      height: 90px;
      .swiper-slide{
        width: 160px;
        height: 86px;
        background: #ffffff;
        border: 1px solid #e1e4ed;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .swiper_top{
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          img{
            width: 13px;
            height: 13px;
            margin-right: 6px;
          }
          .swiper_title{
            display: block;
            width: 115px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            text-align: LEFT;
            color: #000000;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
        .detail_btn{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #87a1e5;
        }
      }
      .swiper-button-prev,.swiper-button-next{
        width: 22px;
        height: 90px;
        left: 0;
        top: 20px;
        background: none;
        background-color: #F2F5FF;
        font-size: 20px;
        line-height: 90px;
        text-align: center;
        color: #D6DBED;
        border-radius: 5px 0 0 5px;
        &:hover{
          background: #e1e8ff;
          color: #AAB6E2;
        }
      }
      .swiper-button-next{
        left: auto;
        right: 0;
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .question_tip{
    display: flex;
    margin: 20px 0 30px;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #000000;
    a{
      color: #FF8B08;
      cursor: pointer;
      i{
        width: 6px;
      }
    }
  }
}
</style>
